<h2>Menu</h2>

<ul class="component_menu">
  <li class="component_menuitem">
    <select id="buildingPicker">
      <option *ngFor="let building of getBuildings()">{{helpersService.printableAddress(building.address,true)}}</option>
    </select>
  </li>
  <li class="component_menuitem" routerLink="/profile1"><a>Dashboard</a></li>
  <li class="component_menuitem" routerLink="/profile1"><a>Verbruik</a></li>
  <li class="component_menuitem" routerLink="/profile1"><a>Notificaties</a></li>
</ul>
