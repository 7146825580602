import { Component, OnInit } from '@angular/core';
import { Building } from '@interfaces/building';
import { AccountService } from '@services/account.service';
import { HelpersService } from '@services/helpers.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  constructor(private accountService: AccountService, public helpersService: HelpersService){
  }

  getBuildings(): Building[]{
    return this.accountService.getMultipleBuildings();
  }

  ngOnInit(): void {
  }
}
