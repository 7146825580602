import { Injectable } from '@angular/core';

import { Account } from '@interfaces/account';
import { Building } from '@interfaces/building';

import { ACCOUNT as SimpleAccount } from '@mocks/mock-user-profile-no-post';
import { ACCOUNT as FullAccount } from '@mocks/mock-user-profile';
import { BUILDING as MockBuilding } from '@mocks/mock-building';
import { BUILDING2 as MockBuilding2 } from '@mocks/mock-building';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor() { }

  getSimpleAccount() : Account {
    return SimpleAccount;
  }

  getFullAccount() : Account {
    return FullAccount;
  }
  
  getPostalName(acc: Account): string {
    if (acc.postAddress){
      return acc.postAddress.name || '';
    }
    return acc.name;
  }

  getMultipleBuildings() : Building[] {
    return [MockBuilding, MockBuilding2];
  }
}
