import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blackout',
  templateUrl: './blackout.component.html',
  styleUrls: ['./blackout.component.css']
})
export class BlackoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
