import { Component, OnInit } from '@angular/core';

import { CognitoService } from '@services/cognito.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {
  constructor(public cognitoService: CognitoService) { }

  ngOnInit(): void {
  }

}
