import { Component, OnInit } from '@angular/core';

import { AccountService } from '@services/account.service';
import { HelpersService } from '@services/helpers.service';
import { BuildingService } from '@services/building.service';
import { Building } from '@interfaces/building';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.css', '../account.component.css']
})
export class BuildingComponent implements OnInit {
  editingValidation: Array<String>;
  
  constructor(private accountService: AccountService, public helpersService: HelpersService, private buildingService: BuildingService){
    this.editingValidation = [];
  }
  ngOnInit(): void {}
  
  validateAddress(address: any){
    
  }

  editBuilding(event: any){
    this.buildingService.editingBuilding = event.srcElement.getAttribute('data-buildingid');
  }

  getBuildings(): Building[]{
    return this.accountService.getMultipleBuildings();
  }

  isEditing(building: Building) : boolean {
    if (!this.buildingService.editingBuilding){
      return false;
    }
    return this.buildingService.editingBuilding == building.id;
  }
  
  validateSaveBuilding(){
    this.buildingService.editingBuilding = undefined;
  }
}
