import { Component, OnInit, Input } from '@angular/core';

import { BuildingService } from '@services/building.service';
import { Building } from '@interfaces/building';

@Component({
  selector: 'app-energy-contract',
  templateUrl: './energy-contract.component.html',
  styleUrls: ['./energy-contract.component.css','../../account.component.css']
})
export class EnergyContractComponent implements OnInit {
  @Input() building?: Building;

  constructor(private buildingService: BuildingService) {
  }

  isEditing(): boolean {
    if (!this.buildingService.editingBuilding){
      return false;
    }
    if (!this.building){
      return false;
    }
    return this.buildingService.editingBuilding == this.building.id;
  }

  ngOnInit(): void {
  }

}
