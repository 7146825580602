<div class="buildingInfo" *ngFor="let building of getBuildings()">
  <div class="buildingInfoContent">
    <div class="infoHeader">
      <h3>Gebouw: <span *ngIf="!isEditing(building)">{{building.buildingName}}</span></h3>
      <button *ngIf="!isEditing(building)" (click)="editBuilding($event)" [attr.data-buildingid]="building.id" class="button" id="inlineEditButton">Wijzig gegevens</button>
      <button *ngIf="isEditing(building)" (click)="validateSaveBuilding()" class="button" id="inlineSaveButton">Opslaan</button>
    </div>
    
    <p>Gebouwnaam: <span *ngIf="!isEditing(building)">{{building.buildingName}}</span></p>
    <input *ngIf="isEditing(building)" [(ngModel)]="building.buildingName">
    
    <p>Is bedrijf: <span *ngIf="!isEditing(building)">{{building.business}}</span></p>
    <input type="checkbox" *ngIf="isEditing(building)" [(ngModel)]="building.business">
    
    <p>Gebouwtype: <span *ngIf="!isEditing(building)">{{building.type}}</span></p>
    <input *ngIf="isEditing(building)" [(ngModel)]="building.type">
    
    <p>Gasloos: <span *ngIf="!isEditing(building)">{{building.gasless}}</span></p>
    <input type="checkbox" *ngIf="isEditing(building)" [(ngModel)]="building.gasless">
    
    <p>Bouwjaar: <span *ngIf="!isEditing(building)">{{building.yearConstructed}}</span></p>
    <input type="number" *ngIf="isEditing(building)" [(ngModel)]="building.yearConstructed">
    
    <p>Bewoners: <span *ngIf="!isEditing(building)">{{building.residents}}</span></p>
    <input type="number" *ngIf="isEditing(building)" [(ngModel)]="building.residents">
    
    <p>Certificaat: <span *ngIf="!isEditing(building)">{{building.certificate}}</span></p>
    <input *ngIf="isEditing(building)" [(ngModel)]="building.certificate">
    
    <p>Oppervlakte: <span *ngIf="!isEditing(building)">{{building.surfaceArea}}</span></p>
    <input *ngIf="isEditing(building)" [(ngModel)]="building.surfaceArea">
     
  <p>Adres: <span *ngIf="!isEditing(building)">{{helpersService.printableAddress(building.address)}}</span></p>
    
    <table *ngIf="isEditing(building)" class="addressTable">
      <tr>
        <td><label for="addressStreet" [ngClass]="{mandatoryField: isEditing(building)}">Straat</label></td>
        <td><label for="addressNumber" [ngClass]="{mandatoryField: isEditing(building)}">Huisnr.</label></td>
        <td><label for="addressAddition">Huisnr. letter</label></td>
      </tr>
      <tr>
        <td><input type="text" id="addressStreet" [(ngModel)]="building.address.street" (blur)="validateAddress('address')" [ngClass]="{invalidInput: editingValidation.includes('streetaddress')}"></td>
        <td><input type="text" id="addressNumber" class="tinyInput" [(ngModel)]="building.address.number" (blur)="validateAddress('address')" [ngClass]="{invalidInput: editingValidation.includes('numberaddress')}"></td>
        <td><input type="text" id="addressAddition" class="tinyInput" [(ngModel)]="building.address.addition" (blur)="validateAddress('address')" minlength="1" maxlength="1"></td>
      </tr>
    </table>
    <table *ngIf="isEditing(building)" class="addressTable">
      <tr>
        <td><label for="addressZip" [ngClass]="{mandatoryField: isEditing(building)}">Postcode</label></td>
        <td><label for="addressCity" [ngClass]="{mandatoryField: isEditing(building)}">Woonplaats</label></td>
      </tr>
      <tr>
        <td><input type="text" id="addressZipcode" [(ngModel)]="building.address.zipcode" (blur)="validateAddress('address')" [ngClass]="{invalidInput: editingValidation.includes('zipcodeaddress')}"></td>
        <td><input type="text" id="addressCity" [(ngModel)]="building.address.city" (blur)="validateAddress('address')" [ngClass]="{invalidInput: editingValidation.includes('cityaddress')}"></td>
      </tr>
    </table>
    <table *ngIf="isEditing(building)" class="addressTable">
      <tr>
        <td><label for="addressNote">Toevoeging</label></td>
      </tr>
      <tr>
        <td><input type="text" id="addressNote" [(ngModel)]="building.address.note" (blur)="validateAddress('address')"></td>
      </tr>
    </table>
  </div>
  
  <app-energy-contract [building]="building"></app-energy-contract>

  <!--
  <div class="energyContractContent" *ngIf="isEditing(building)">
    <h3>Energiecontract</h3>
    
    <label for="contractSupplier">Energie leverancier</label>
    <input id="contractSupplier" [(ngModel)]="building.energyContract.supplier">
    
    <label for="contractStart">Startdatum energiecontract</label>
    <input id="contractStart" [(ngModel)]="building.energyContract.startDate">
    
    <label for="contractEnd">Einddatum energiecontract</label>
    <input id="contractEnd" [(ngModel)]="building.energyContract.endDate">
    
    <label for="contractCostPerMonth">Kosten per maand</label>
    <input id="contractCostPerMonth" [(ngModel)]="building.energyContract.costPerMonth">
    
    <label for="contractElectricHigh">Elektra hoog</label>
    <input id="contractElectricHigh" [(ngModel)]="building.energyContract.electricHigh">
    
    <label for="contractElectricLow">Elektra laag</label>
    <input id="contractElectricLow" [(ngModel)]="building.energyContract.electricLow">
    
    <label for="contractGas">Gas</label>
    <input id="contractGas" [(ngModel)]="building.energyContract.gas">
    
    <label for="contractReturnRate">Teruglevertarieven</label>
    <input id="contractReturnRate" [(ngModel)]="building.energyContract.returnRate">
    
    <label for="contractVariableRate">Variabel tarief</label>
    <input id="contractVariableRate" [(ngModel)]="building.energyContract.variableRate">
  </div>
  -->
</div>
