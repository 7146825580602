import { Component, OnInit } from '@angular/core';

import { AccountService } from '@services/account.service';
import { HelpersService } from '@services/helpers.service';
import { Account } from '@interfaces/account'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css', '../account.component.css']
})

export class ProfileComponent implements OnInit {
  account: Account;
  editingPerson: boolean;
  editingLogin: boolean;
  editingValidation: Array<String>;
  
  constructor(private accountService: AccountService, public helpersService: HelpersService){
    this.editingPerson = false;
    this.editingLogin = false;
    this.editingValidation = [];
    this.account = this.accountService.getSimpleAccount();
  }
  
  ngOnInit(): void {}
  
  getPostalName() : string {
    return this.accountService.getPostalName(this.account);
  }
  
  deleteItemFromArray(index: number){
    if (index != -1){
      this.editingValidation.splice(index, 1);
    }
  }
  
  changePostAddress(event: any){
    if (event.target.checked){
      this.account.postAddress = null;
    } else {
      this.account.postAddress = {
        'country' : this.account.address.country,
        'province' : this.account.address.province,
        //'address': this.account.address.address,
        'street' : this.account.address.street,
        'number' : this.account.address.number,
        'addition' : this.account.address.addition,
        'note' : this.account.address.note,
        'zipcode' : this.account.address.zipcode,
        'city': this.account.address.city
      }
      this.validateAddress('postAddress');
    }
  }
  
  validateName(){
    this.deleteItemFromArray(this.editingValidation.indexOf('firstName'));
    this.deleteItemFromArray(this.editingValidation.indexOf('lastName'));
    this.account.nameInitials = (this.account.nameInitials != null ? this.account.nameInitials.toUpperCase() : '');
    this.account.name = this.account.nameFirst + ' ' + (this.account.nameInsertion != null ? this.account.nameInsertion + ' ' : '') + this.account.nameLast;
    
    if (this.account.nameFirst.length <= 2){
      this.editingValidation.push('firstName');
    }
    if (this.account.nameLast.length <= 2){
      this.editingValidation.push('lastName');
    }
  }
  
  validateEmail(){
    this.deleteItemFromArray(this.editingValidation.indexOf('email'));
    var validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!this.account.email.match(validEmail)){
      this.editingValidation.push('email');
    }
  }
  
  validatePhonenumber(){
    this.deleteItemFromArray(this.editingValidation.indexOf('phone'));
    this.deleteItemFromArray(this.editingValidation.indexOf('phoneCode'));
    var phoneNumber = (this.account.phone != null ? this.account.phone.toString() : '');
    if (phoneNumber != '' && (phoneNumber.length < 7 || phoneNumber.length > 13)){
      this.editingValidation.push('phone');
    }
    
    if (this.account.phoneCode == ''){
      this.editingValidation.push('phoneCode');
    }
  }
  
  validateAddress(addressType: String){
    this.deleteItemFromArray(this.editingValidation.indexOf('street' + addressType));
    this.deleteItemFromArray(this.editingValidation.indexOf('number' + addressType));
    this.deleteItemFromArray(this.editingValidation.indexOf('zipcode' + addressType));
    this.deleteItemFromArray(this.editingValidation.indexOf('city' + addressType));
    
    var thisAddress = (addressType == 'address' ? this.account.address : this.account.postAddress);
    
    if (thisAddress != null){
      thisAddress.city = thisAddress.city.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
      
      if (thisAddress.street.length <= 2 || !thisAddress.number  && thisAddress.zipcode.length != 6 && thisAddress.city.length <= 2){
        if (thisAddress.street.length <= 2){
          this.editingValidation.push('street' + addressType);
        }
        
        if (thisAddress.number <= 0){
          this.editingValidation.push('number' + addressType);
        }
        
        if (thisAddress.zipcode.length != 6){
          this.editingValidation.push('zipcode' + addressType);
        }
        
        if (thisAddress.city.length <= 2){
          this.editingValidation.push('city' + addressType);
        }
      }
    }
  }
  
  validateSaveAccount(){
    if (this.editingValidation.length == 0){
      this.editingPerson = false;
    }
  }
  
  validateSaveLogin(){
    this.editingLogin = false;
  }
}
