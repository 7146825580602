<div *ngIf="building">
  <div class="energyContractContent" *ngIf="isEditing()">
    <h3>Energiecontract</h3>
    
    <label for="contractSupplier">Energie leverancier</label>
    <input id="contractSupplier" [(ngModel)]="building.energyContract.supplier">
    
    <label for="contractStart">Startdatum energiecontract</label>
    <input id="contractStart" [(ngModel)]="building.energyContract.startDate">
    
    <label for="contractEnd">Einddatum energiecontract</label>
    <input id="contractEnd" [(ngModel)]="building.energyContract.endDate">
    
    <label for="contractCostPerMonth">Kosten per maand</label>
    <input id="contractCostPerMonth" [(ngModel)]="building.energyContract.costPerMonth">
    
    <label for="contractElectricHigh">Elektra hoog</label>
    <input id="contractElectricHigh" [(ngModel)]="building.energyContract.electricHigh">
    
    <label for="contractElectricLow">Elektra laag</label>
    <input id="contractElectricLow" [(ngModel)]="building.energyContract.electricLow">
    
    <label for="contractGas">Gas</label>
    <input id="contractGas" [(ngModel)]="building.energyContract.gas">
    
    <label for="contractReturnRate">Teruglevertarieven</label>
    <input id="contractReturnRate" [(ngModel)]="building.energyContract.returnRate">
    
    <label for="contractVariableRate">Variabel tarief</label>
    <input id="contractVariableRate" [(ngModel)]="building.energyContract.variableRate">
  </div>
</div>
