import { Account } from '@interfaces/account';

export const ACCOUNT : Account = 
{
  'name': 'Mr. Userson',
  'nameFirst': 'Bill',
  'nameInitials': 'B.M',
  'nameInsertion': 'of',
  'nameLast': 'Userson',
  'phone': null,
  'phoneCode' : '+031',
  'mobile': null,
  'postAddress': null,
  'email': 'test@mail.address',
  'login' : 'test@mail.address',
  'clientNumber' : 123,
  'address': {
    'country' : 'Nederland',
    'province' : 'Friesland',
    'street' : 'Road Street',
    'number' : 42,
    'addition' : '',
    'note' : '',
    'city': 'Mock City',
    'zipcode': '1337OK'
  }
}
