import { Building } from '@interfaces/building';

export const BUILDING : Building = 
{
  'buildingName': 'Huisje',
  'address': {
    'country' : 'Nederland',
    'province' : 'Friesland',
    'street' : 'Road Street',
    'number' : 42,
    'addition' : '',
    'note' : '',
    'city': 'Mock City',
    'zipcode': '1337OK'
  },
  'energyContract' : {
    name: 'Test',
    supplier: 'Engie',
    startDate: new Date(),
    endDate: new Date(),
    costPerMonth: 123,
    electricHigh: 12,
    electricLow: 8,
    gas: 14,
    returnRate: 1232,
    variableRate: 3213
  },
  'business' : false,
  'businessBuildingType' : null,
  'type' : 'Flat',
  'gasless' : false,
  'yearConstructed' : 1987,
  'residents' : 4,
  'certificate' : 'B',
  'surfaceArea' : '150-200m3',
  'id' : 12321
}

export const BUILDING2 : Building = 
{
  'buildingName': 'Vakantie Huisje',
  'address': {
    'country' : 'Nederland',
    'province' : 'Friesland',
    'street' : 'Road Street',
    'number' : 42,
    'addition' : 'a',
    'note' : 'Vakantie',
    'city': 'Mock City',
    'zipcode': '1337OK'
  },
  'energyContract' : {
    name: 'Test',
    supplier: 'Engie',
    startDate: new Date(),
    endDate: new Date(),
    costPerMonth: 123,
    electricHigh: 12,
    electricLow: 8,
    gas: 14,
    returnRate: 1232,
    variableRate: 3213
  },
  'business' : false,
  'businessBuildingType' : null,
  'type' : 'Flat',
  'gasless' : false,
  'yearConstructed' : 1987,
  'residents' : 4,
  'certificate' : 'B',
  'surfaceArea' : '150-200m3',
  'id' : 12322
}
