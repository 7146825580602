import { Component, OnInit } from '@angular/core';

import {Router} from '@angular/router';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.css']
})
export class AccountMenuComponent implements OnInit {
  constructor(private router: Router) { }
  ngOnInit(): void {}

  isActive(module: string): boolean {
    return this.router.url.endsWith('/' + module);
  }
}
