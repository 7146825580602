import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { CognitoService } from '@services/cognito.service';

@Component({
  selector: 'app-cognito',
  templateUrl: './cognito.component.html',
  styleUrls: ['./cognito.component.css']
})
export class CognitoComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, 
              private cognitoService: CognitoService, 
              private router: Router) {

    this.activatedRoute.queryParamMap.subscribe(
      (params: ParamMap) => { 
        let val = params.get('code');
        if (val){
          this.cognitoService.setAuthCode(val);
        }
      }
    );

    this.cognitoService.doneAuth.subscribe(v => {
      this.router.navigate(['/application']);
    });
  }

  ngOnInit(): void {
  }

}
