<div class="accountMenu">
  <p routerLink="profile" [ngClass]="{accountMenuActive : isActive('profile')}">
     Account 
  </p>
  <p routerLink="buildings" [ngClass]="{accountMenuActive : isActive('buildings')}">
     Gebouwen
  </p>
  <p routerLink="finance" [ngClass]="{accountMenuActive : isActive('finance')}">
    Financi&euml;n
  </p>
  <p routerLink="connections" [ngClass]="{accountMenuActive : isActive('connections')}">
    Koppelingen
  </p>
  <p routerLink="preferences" [ngClass]="{accountMenuActive : isActive('preferences')}">
    Voorkeuren
  </p>
</div>
