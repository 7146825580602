import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from '@components/header/header.component';
import { MenuComponent } from '@components/menus/main-menu/menu.component';
import { AccountMenuComponent } from './components/menus/account-menu/account-menu.component';
import { AccountComponent } from './components/account/account.component';
import { ProfileComponent } from '@components/account/profile/profile.component';
import { BuildingComponent } from './components/account/building/building.component';
import { FinanceComponent } from './components/account/finance/finance.component';
import { ConnectionComponent } from './components/account/connection/connection.component';
import { PreferenceComponent } from './components/account/preference/preference.component';
import { EnergyContractComponent } from './components/account/building/energy-contract/energy-contract.component';
import { BlackoutComponent } from './components/blackout/blackout.component';
import { ApplicationComponent } from './components/application/application.component';
import { CognitoComponent } from './components/cognito/cognito.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    AccountMenuComponent,
    AccountComponent,
    ProfileComponent,
    BuildingComponent,
    FinanceComponent,
    ConnectionComponent,
    PreferenceComponent,
    EnergyContractComponent,
    BlackoutComponent,
    ApplicationComponent,
    CognitoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
