import { Account } from '@interfaces/account';

export const ACCOUNT : Account = 
{
  'name': 'Mr. Userson',
  'nameFirst': 'Bill',
  'nameInitials': null,
  'nameInsertion': null,
  'nameLast': 'Userson',
  'phone': null,
  'phoneCode' : '+031',
  'mobile':'06-12345678',
  'email': 'test@mail.address',
  'login' : 'test@mail.address',
  'clientNumber' : 1234,
  'address': {
    'country' : 'Nederland',
    'province' : 'Friesland',
    'street' : 'Road Street',
    'number' : 42,
    'addition' : null,
    'note' : null,
    'zipcode' : '1337OK',
    'city': 'Mock City'
  },
  'postAddress': {
    'country' : 'Nederland',
    'name': 'Mr. Users Son',
    'province' : 'Friesland',
    'street' : 'Real Address',
    'number' : 23,
    'addition' : 'A',
    'note' : null,
    'city': 'Actual Place',
    'zipcode': '9876XL',
  },
}
