import { Injectable, EventEmitter } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';


import { Token } from '@interfaces/token';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  authorization_code?: string
  resp?: Token;

  doneAuth = new EventEmitter<boolean>();


  constructor(private http: HttpClient) { 
  }

  setAuthCode(v: string){
    this.authorization_code = v;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };


    this.http.post(
      "https://enelogic-development-test.auth.eu-west-1.amazoncognito.com/oauth2/token",
      "grant_type=authorization_code&" +
      "client_id=7n9q3j7s4q5c50f2d113ob9jft&" +
      "client_secret=1pbnep3dq4h0fi7u9goh3l5t9aq8tom38gp6g2r6rctnup524drt&" +
      "redirect_uri=https://development.enelogic.nl:8443/cognito/&" +
      "code=" + this.authorization_code,
      httpOptions
    ).subscribe((v:any) => {
      this.resp = v
      localStorage.setItem("access_token", v.access_token);
      localStorage.setItem("id_token", v.id_token);
      localStorage.setItem("refresh_token", v.refresh_token);
      this.doneAuth.emit(true);
    });
  }
}
