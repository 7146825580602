import { NgModule} from '@angular/core';
import { RouterModule, Routes} from '@angular/router';

import { BlackoutComponent } from '@components/blackout/blackout.component';

import { CognitoComponent } from '@components/cognito/cognito.component';

import { ApplicationComponent } from '@components/application/application.component'
  import { AccountComponent } from '@components/account/account.component';
    import { ProfileComponent } from '@components/account/profile/profile.component';
    import { BuildingComponent } from '@components/account/building/building.component';
    import { FinanceComponent } from '@components/account/finance/finance.component';
    import { ConnectionComponent } from '@components/account/connection/connection.component';
    import { PreferenceComponent } from '@components/account/preference/preference.component';



const routes: Routes = [
  { path: '', pathMatch: 'full', component: BlackoutComponent},
  { path: 'application', 
    component: ApplicationComponent,
    children: [
      { path: 'account', 
        component: AccountComponent,
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'profile'},
          { path: 'profile', component: ProfileComponent},
          { path: 'buildings', component: BuildingComponent},
          { path: 'finance', component: FinanceComponent},
          { path: 'connections', component: ConnectionComponent},
          { path: 'preferences', component: PreferenceComponent},

        ]
      },
      { path: 'cognito',
        component: CognitoComponent
      }
    ]
  },
  { path: 'cognito',
    component: CognitoComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

