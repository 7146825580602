<div class="accountInfo">
  <div class="accountData">
  <div class="infoHeader">
    <h3>Contactgegevens</h3>
    <button *ngIf="!editingPerson" (click)="editingPerson = true" class="button" id="inlineEditButton">Wijzig gegevens</button>
    <button *ngIf="editingPerson" (click)="validateSaveAccount()" class="button" id="inlineSaveButton">Opslaan</button>
  </div>
  
  <p class="infoHeader">Uw klantnummer: {{account.clientNumber}}</p>
  
  <p class="infoHeader" [ngClass]="{mandatoryField: editingPerson}">Naam: <span *ngIf="!editingPerson">{{account.name}}</span></p>
  
  <table *ngIf="editingPerson" class="nameTable">
    <tr>
      <td><label for="editingInitials">Initialen</label></td>
      <td><label for="editingFirstName" [ngClass]="{mandatoryField: editingPerson}">Voornaam</label></td>
      <td><label for="editingInsertion">Tussenvoegsel</label></td>
      <td><label for="editingLastName" [ngClass]="{mandatoryField: editingPerson}">Achternaam</label></td>
    </tr>
    <tr>
      <td><input *ngIf="editingPerson" [(ngModel)]="account.nameInitials" (blur)="validateName()" class="tinyInput"></td>
      <td><input *ngIf="editingPerson" [(ngModel)]="account.nameFirst" (blur)="validateName()" [ngClass]="{invalidInput: editingValidation.includes('firstName')}"></td>
      <td><input *ngIf="editingPerson" [(ngModel)]="account.nameInsertion" (blur)="validateName()" class="tinyInput"></td>
      <td><input *ngIf="editingPerson" [(ngModel)]="account.nameLast" (blur)="validateName()" [ngClass]="{invalidInput: editingValidation.includes('lastName')}"></td>
    </tr>
  </table>
  
  <p class="infoHeader" [ngClass]="{mandatoryField: editingPerson}">E-mailadres: <span *ngIf="!editingPerson">{{account.email}}</span></p>
  <input *ngIf="editingPerson" [(ngModel)]="account.email" (blur)="validateEmail()" [ngClass]="{invalidInput: editingValidation.includes('email')}">
  
  <p class="infoHeader" *ngIf="account.postAddress && !editingPerson">Postadres: {{helpersService.printableAddress(account.postAddress)}}</p>
  
  <p class="infoHeader" *ngIf="account.phone != null && !editingPerson">Telefoonnummer: <span>{{account.phone}}</span></p>
  <p class="infoHeader" *ngIf="editingPerson">Telefoonnummer:</p>
  <input *ngIf="editingPerson" [(ngModel)]="account.phoneCode" (blur)="validatePhonenumber()" [ngClass]="{invalidInput: editingValidation.includes('phoneCode')}" class="tinyInput">
  <input type="number" *ngIf="editingPerson" [(ngModel)]="account.phone" (blur)="validatePhonenumber()" [ngClass]="{invalidInput: editingValidation.includes('phone')}">
  
  <p class="infoHeader" *ngIf="account.mobile != null && !editingPerson">Mobiel: <span>{{account.mobile}}</span></p>
  <p class="infoHeader" *ngIf="editingPerson">Mobiel:</p>
  <input type="number" *ngIf="editingPerson" [(ngModel)]="account.mobile">
  
  <p class="infoHeader">Adres: <span *ngIf="!editingPerson">{{helpersService.printableAddress(account.address)}}</span></p>
  <p class="infoHeader" *ngIf="!editingPerson">Woonplaats: <span>{{account.address.city}}</span></p>
  
  <p class="infoHeader">Land: <span *ngIf="!editingPerson">{{account.address.country}}</span></p>
  <input *ngIf="editingPerson" [(ngModel)]="account.address.country">
  
  <table *ngIf="editingPerson" class="addressTable">
    <tr>
      <td><label for="addressStreet" [ngClass]="{mandatoryField: editingPerson}">Straat</label></td>
      <td><label for="addressNumber" [ngClass]="{mandatoryField: editingPerson}">Huisnr.</label></td>
      <td><label for="addressAddition">Huisnr. letter</label></td>
    </tr>
    <tr>
      <td><input type="text" id="addressStreet" [(ngModel)]="account.address.street" (blur)="validateAddress('address')" [ngClass]="{invalidInput: editingValidation.includes('streetaddress')}"></td>
      <td><input type="text" id="addressNumber" class="tinyInput" [(ngModel)]="account.address.number" (blur)="validateAddress('address')" [ngClass]="{invalidInput: editingValidation.includes('numberaddress')}"></td>
      <td><input type="text" id="addressAddition" class="tinyInput" [(ngModel)]="account.address.addition" (blur)="validateAddress('address')" minlength="1" maxlength="1"></td>
    </tr>
  </table>
  <table *ngIf="editingPerson" class="addressTable">
    <tr>
      <td><label for="addressZip" [ngClass]="{mandatoryField: editingPerson}">Postcode</label></td>
      <td><label for="addressCity" [ngClass]="{mandatoryField: editingPerson}">Woonplaats</label></td>
    </tr>
    <tr>
      <td><input type="text" id="addressZipcode" [(ngModel)]="account.address.zipcode" (blur)="validateAddress('address')" [ngClass]="{invalidInput: editingValidation.includes('zipcodeaddress')}"></td>
      <td><input type="text" id="addressCity" [(ngModel)]="account.address.city" (blur)="validateAddress('address')" [ngClass]="{invalidInput: editingValidation.includes('cityaddress')}"></td>
    </tr>
  </table>
  <table *ngIf="editingPerson" class="addressTable">
    <tr>
      <td><label for="addressNote">Toevoeging</label></td>
    </tr>
    <tr>
      <td><input type="text" id="addressNote" [(ngModel)]="account.address.note" (blur)="validateAddress('address')"></td>
    </tr>
  </table>
  <div *ngIf="editingPerson">
    <input type="checkbox" id="sameAddress" [checked]="account.postAddress == null" (click)="changePostAddress($event)">
    <label for="sameAddress" >Gebruik als postadres</label>
  </div>
  
  <h3>Logingegevens</h3>
  <button *ngIf="!editingLogin" (click)="editingLogin = true" class="button" id="inlineEditButton">Wijzig gegevens</button>
  <div class="editAccountBlock" *ngIf="editingLogin">
    <label for="loginCurrent">Huidig e-mailadres</label>
    <input id="loginCurrent" [(ngModel)]="account.login">
    <label for="loginNew">Nieuw e-mailadres</label>
    <input id="loginNew">
    <label for="loginRepeat">Herhaal e-mailadres</label>
    <input id="loginRepeat">
    
    <label for="passwordCurrent">Huidig wachtwoord</label>
    <input type="password" id="passwordCurrent">
    <label for="passwordNew">Nieuw wachtwoord</label>
    <input type="password" id="passwordNew">
    <label for="passwordRepeat">Wachtwoord herhaling</label>
    <input type="password" id="passwordRepeat">
  </div>
  <button *ngIf="editingLogin" (click)="validateSaveLogin()" class="button" id="inlineSaveButton">Opslaan</button>
</div>

<div class="postAddressData" *ngIf="editingPerson && account.postAddress != null">
  <p class="infoHeader">Postadres: <span *ngIf="!editingPerson">{{helpersService.printableAddress(account.postAddress)}}</span></p>
  <p class="infoHeader" *ngIf="!editingPerson">Woonplaats: <span>{{account.postAddress.city}}</span></p>
  
  <p class="infoHeader">Land: <span *ngIf="!editingPerson">{{account.postAddress.country}}</span></p>
  <input *ngIf="editingPerson" [(ngModel)]="account.postAddress.country">
  
  <table *ngIf="editingPerson" class="addressTable">
    <tr>
      <td><label for="postAddressStreet" [ngClass]="{mandatoryField: editingPerson}">Straat</label></td>
      <td><label for="postAddressNumber" [ngClass]="{mandatoryField: editingPerson}">Huisnr.</label></td>
      <td><label for="postAddressAddition">Huisnr. letter</label></td>
    </tr>
    <tr>
      <td><input type="text" id="postAddressStreet" [(ngModel)]="account.postAddress.street" (blur)="validateAddress('postAddress')" [ngClass]="{invalidInput: editingValidation.includes('streetpostAddress')}"></td>
      <td><input type="text" id="postAddressNumber" class="tinyInput" [(ngModel)]="account.postAddress.number" (blur)="validateAddress('postAddress')" [ngClass]="{invalidInput: editingValidation.includes('numberpostAddress')}"></td>
      <td><input type="text" id="postAddressAddition" class="tinyInput" [(ngModel)]="account.postAddress.addition" (blur)="validateAddress('postAddress')" minlength="1" maxlength="1"></td>
    </tr>
  </table>
  <table *ngIf="editingPerson" class="addressTable">
    <tr>
      <td><label for="postAddressZipcode" [ngClass]="{mandatoryField: editingPerson}">Postcode</label></td>
      <td><label for="postAddressCity" [ngClass]="{mandatoryField: editingPerson}">Woonplaats</label></td>
    </tr>
    <tr>
      <td><input type="text" id="postAddressZipcode" [(ngModel)]="account.postAddress.zipcode" (blur)="validateAddress('postAddress')" [ngClass]="{invalidInput: editingValidation.includes('zipcodepostAddress')}"></td>
      <td><input type="text" id="postAddressCity" [(ngModel)]="account.postAddress.city" (blur)="validateAddress('postAddress')" [ngClass]="{invalidInput: editingValidation.includes('citypostAddress')}"></td>
    </tr>
  </table>
  <table *ngIf="editingPerson" class="addressTable">
    <tr>
      <td><label for="postAddressNote">Toevoeging</label></td>
    </tr>
    <tr>
      <td><input type="text" id="postAddressNote" [(ngModel)]="account.postAddress.note" (blur)="validateAddress('postAddress')"></td>
    </tr>
  </table>
</div>
