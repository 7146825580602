import { Injectable } from '@angular/core';

import { Address } from '@interfaces/address';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor() { }

  printableAddress(address: Address, short: boolean = false) : string {
    let printable = address.street + ' ' + address.number + (address.addition ? address.addition : '');
    if(!short){
      printable += ', ' + address.zipcode + ', ' + address.city + ', ' + address.province;
    }
    return printable;
  }

}
